import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 3 hr. `}<meta itemProp="cookTime" content="PT3H" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Clam juice, 1 8 oz. bottle</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red potatoes, 2 lbs cubed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bacon, 16 oz. chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onions, 2 cups diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Celery, 1 1/4 cups diced (~2-4 stalks)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 2 cloves chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bay leaf, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baby clams, 3 10 oz. cans</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Half and half, 1 1/4 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen corn, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Cut potatoes into 1 inch cubes</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop bacon into 1/4 inch pieces</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice onions</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice celery</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Boil potatoes, clam juice, and 1 cup water in medium pot until potatoes are tender, about 10 minutes. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While potatoes are boiling, cook bacon and butter in large pot on medium heat until bacon starts to brown, about 8 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add onions, celery, garlic, and bay leaf to large pot until vegetables are soft, about 6 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in flour, cook about 2 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add clam juice from canned clams, mix well between each can.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add potato mixture.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add clams, half and half, frozen corn, and light amount of cayenne, salt, and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bring to light boil, then simmer on low heat for 2 hours.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Season with cayenne, salt, and pepper to taste.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Let rest for 20-30 minutes before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      